import React from 'react'
import './footer.css'

import ERC from '../../assets/supported/LOGO_ERC-FLAG_EU.png'
import Warwick from '../../assets/supported/Warwick.png'
import LMU from '../../assets/supported/LMU_Logo_CMYK_InvertiertSchwarz.png'
import Twitter from '../../assets/Logo/Social media icons/Twitter/2021 Twitter logo - black.png'
import instagram from '../../assets/Logo/Social media icons/Instagram/Instagram_Glyph_Black.png'
import { NavLink } from 'react-router-dom'

const Footer = () => {
  return (
    <footer>
      <div className='supported-container'>
        <p>Supported by:</p>
        <img src={LMU} alt="Ludvig Maximilians-Universität München logo"/>
        <img src={Warwick} alt="University of Warwick logo" />
        <img src={ERC} alt="European Research Counsil logo" />
      </div>

      <div className='info-container'>
      <p>This project has received funding from the European Research Council(ERC)
        under the European Union’s Horizon 2020 research and innovation programme
        (Grant agreement No.101019879).</p>
      </div>

      <div className='some-container'>
      <a href="https://twitter.com/TheatreAmateurs">
          <img className="some-logo" src={Twitter} alt='twitter logo'></img>
        </a>
        <a href="https://www.instagram.com/theatreamateurs/">
          <img className="some-logo" src={instagram} alt='instagram logo'></img>
        </a>
        <a href="mailto:performingcitizenship@lmu.de"><p>Kontakt</p></a>
        <NavLink to="/imprint"><p>Imprint</p></NavLink>
      </div>

    </footer>
  )
}

export default Footer