// TwitterContainer.js
import React, { useEffect } from "react";
import { TwitterTimelineEmbed, TwitterShareButton, TwitterFollowButton, TwitterHashtagButton, TwitterMentionButton, TwitterTweetEmbed, TwitterMomentShare, TwitterDMButton, TwitterVideoEmbed, TwitterOnAirButton } from 'react-twitter-embed';


const Tweets = () => {
  // useEffect(() => {
  //   const anchor = document.createElement("a");
  //   anchor.setAttribute("class", "twitter-timeline");
  //   anchor.setAttribute("data-theme", "light");
  //   anchor.setAttribute("data-tweet-limit", "5");
  //   anchor.setAttribute("data-chrome", "noheader nofooter noborders");
  //   anchor.setAttribute("href", "https://twitter.com/TheatreAmateurs");
  //   document.getElementsByClassName("twitter-embed")[0].appendChild(anchor);

  //   const script = document.createElement("script");
  //   script.setAttribute("src", "https://platform.twitter.com/widgets.js");
  //   document.getElementsByClassName("twitter-embed")[0].appendChild(script);
  // }, []);

  return (
    <section className="twitterContainer tweets-container">
    <TwitterTimelineEmbed
      sourceType="profile"
      screenName="TheatreAmateurs"
      options={{height: 400}}
    />
      {/* <div className="twitter-embed"></div> */}
    </section>
  );
};

export default Tweets;
